.content{
 margin:1vh auto;
 width:100%;
 display:flex;
 flex-flow:row wrap;
 min-height: 550px;
 text-align: center;
 border: 3px solid var(--text-color);
 box-shadow: 0px 0px 7px var(--off-color);


}
.nes-container.is-dark::after {
  background-color:transparent !important;
}
.left {
  width:39%;
  display: flex;
}

.right {
  width:59%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height:100%;
}
.social img {
  margin:auto;
  width:25px
}
.social a {
  display:flex;
  flex-direction: column;
  font-size: 10px;
  color:var(--text-color)
}
.social a:hover {
  color:var(--main-color)
}
.overflow {
  overflow:auto;
}
.item {
  position: relative;
}

#pixel-gif {
    width:75%;
    margin: auto;
    
}

.btn {
  animation: beat 1.25s linear infinite alternate;
  width:50%;

}


ul {
    list-style: none;
    margin:auto;
    /* overflow: hidden; */
    max-width: max-content;
    box-shadow: 0px 0px 10px var(--main-color);
    z-index:9 !important;
    
}



li {
    margin: 13px auto;
    font-family: var(--font-main);
    color: white;
    opacity: 0.7;
    text-shadow: 2px 0px 2px rgb(255, 0, 21, 0.6), -2px 0px 2px var(--off-color);
    text-align: left;
    transition: border-bottom 0.7s ease;
}
li::before {
  visibility: hidden;
  content: "▶ "
}
li:hover::before {
  visibility: visible;
  content: "▶ ";
  color: white;
  opacity: 0.7;
  text-shadow: 2px 0px 2px rgb(255, 0, 21, 0.6), -2px 0px 2px var(--off-color);
  
}

li:hover, .btn:hover {
    opacity: 0.9;
    animation: beat 1.15s linear infinite alternate;
  }
  @keyframes beat {
    0% {
      transform: scale(1.05);
    }
    25% {
      transform: scale(1.10);
    }
    50% {
      transform: scale(1.15);
    }
    75% {
      transform: scale(1.10);
    }
    100% {
      transform: scale(1.05);
    }
  }
@media only screen and (max-width: 600px) {
    .content {
     min-height: 450px;
     border: 0px solid var(--off-color);
     box-shadow: 0px 0px 0px var(--off-color);
    }
    .left {
      height:30%
    }
    .right {
      height:70%;
    }
    #pixel-gif {
      width:30%;
    }
    .content > div {
      width:100%;
      
    }
    .social a span {
      display:none;
    }
    ul {
      width:90%;
      margin:30% auto;
    }

    li {
      text-align: center;
      font-size: 12px;
    }
    li::before {
      content: "‎"
    }
}