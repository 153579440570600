.le-modal {
    width: 90%;
    height:86%;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background:#212529;
    border: 3px white solid;
    z-index:10;
    padding:1rem;
    background:black;
    display:flex;
    flex-direction: column;
    overflow: auto;
    color:var(--text-color);
 };

/* .modal-button {
    background:white !important;
    width:50%  !important;
    margin:1vh auto  !important;
    height:min-content  !important;
    font-size:15px  !important;
} */

