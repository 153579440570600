@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Seaweed+Script&display=swap');
.s-div {
    display:flex;
    background:rgb(88,105,157);
    background: linear-gradient(180deg, 
     rgba(13,48,112,1) 3%,
     rgba(88,105,159,1) 37%, 
     rgba(184,105,172,1) 65%, 
     rgba(253,236,219,1) 90%);
    width:100%;
    height:100vh;
    overflow: scroll;
}
.s-text {
    display:flex;
    flex-direction: column;
    margin: auto;
    max-height: 100vh;
    width:80%;

}
.s-text p {
    font-family: 'Quicksand', sans-serif;
    font-size:2rem;
    font-weight: bold;
    transition: all 0.25s

}
.s-title {
    font-family: 'Seaweed Script', cursive;
    color:white;
    font-size: 4rem;
}
.s-div img {
    height:min-content;
    bottom:0;
    align-self: flex-end;

    transition: all 0.25s
}

@media only screen and (max-width: 600px) {
    .s-div {
      flex-wrap: wrap;
      
    }
    .s-text p {
        font-size: 1.1rem;
    }
    .s-div img {
     order:5;
     margin:auto;
     transform:scale(0.5)
    }

  }


