.technologies {
    display:flex;
    flex-flow: row wrap;
    justify-content:center;
}
.tech img {
    width:200px;
    margin:15px;

}
@media only screen and (max-width: 900px) {
    .tech img {
        width:100px;
    }
}