/* #main-banner {
    display:flex;
    background-image:url("../assets/buidings.gif");
    background-position: center;
    background-repeat: repeat-x;
    background-size: cover;
    image-rendering: pixelated;
    animation: 120s panorama infinite linear;
    justify-content:space-around;
    align-items:center;
    width:100%;
    flex-flow:row wrap;

    height:25vh;

    Code in case this panoram looks badd :<
} */

#main-banner {
    display:flex;
    background-image:
        url("../assets/fence.png"),
        url("../assets/train.gif"),
        url("../assets/buidings.gif");

    background-position: 
        0 0,
        -10000px 50%,
        0 0
    ;
    background-repeat: repeat-x;
    background-size: 
       cover;
    image-rendering: pixelated;
    animation: 120s panorama infinite linear;
    justify-content:space-around;
    align-items:center;
    width:100%;
    flex-flow:row wrap;

    height:25vh;
}


#name-container > h1 {
    color: red;
    margin: 0 auto;
  }

#me::before {
    content: "JA";
    font-family: var(--font-main);
    font-size: 30px;
    color: var(--text-color);
    text-shadow: 0px 0px 10px var(--main-color);
    opacity: 1;
    text-align: center;
}
#me {
    background-image: url("../assets/pixel_me.png");
    width: 10vw;
    min-height: 10vw;
    border-radius: 50%;
    image-rendering: pixelated;
    opacity: 0.85;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 2px -2px 1px 1px rgb(255, 0, 21, 0.6),
        -2px 2px 1px 1px rgb(109, 249, 247, 0.6);

    transition: background-image 0.5s ease-in-out;
}
#me:hover {
    background-image: url("../assets/me.png")
}

#menu {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    font-family: var(--main-font);
}

@keyframes panorama {
    100% {
        background-position: 
        -5000px 0%,
        0px 0,
        -1300px 0%
         
    }
}

@media only screen and (max-width: 600px) {
    #me {
        width:25vw;
        height:25vw;
    }
    #me::before {
        font-size:15px;
        
    }
}
