.about-me {
    padding:1vh
}
@media only screen and (max-width: 600px) {
    p {
        font-size:10px
    }
    .about-me button {
        color:var(--main-color);
    }
}