
.App {
  width:100%;
  height:100%;
  display:flex;
  flex-flow: column wrap;
  align-content: center;
  text-align: center;
}
.background-static {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent
    url("./assets/noise.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 1s infinite;
  opacity: 1;
  visibility: visible;
  background-color: rgb(29, 33, 36);
  z-index:-100
}
.main-wrapper {
  width:75%;
  overflow: hidden;
}
#footer {
  position: absolute;
  font-family: var(--font-main);
  color:var(--text-color);
  bottom:0;
  opacity: 0.6;
  width:100%;
  text-align: center;
  margin-bottom: 25px;
} #footer p {
  margin: 2px;
}
@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}

@media only screen and (max-width: 600px) {
  .main-wrapper {
      width:100%;
  }
  #footer p{
    /* position: absolute; */
    font-size:9px;
  }
}