@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Seaweed+Script&display=swap);
:root {
  --text-color: rgb(247, 247, 247);
  --main-color: #e5398c;
  --off-color: rgb(109, 249, 247, 0.6);
  --font-main: "Press Start 2P", cursive;
  --font-text: "Teko", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    outline:none;
}
body {
  background: #242025;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

h1 {
  max-width: -webkit-max-content;
  max-width: max-content;
  font-size: 40px;
  font-family: "Press Start 2P", cursive;
  font-family: var(--font-main);
  border-bottom: 3px solid #e5398c;
  border-bottom: 3px solid var(--main-color);
  opacity: 0.6;
  margin:auto 10px;
}
.retro {
  color: rgb(247, 247, 247);
  color: var(--text-color);
  opacity: 0.9;
  text-shadow: 5px 0px 2px rgb(255, 0, 21, 0.6), -5px 0px 2px rgb(109, 249, 247, 0.6);
  text-shadow: 5px 0px 2px rgb(255, 0, 21, 0.6), -5px 0px 2px var(--off-color);
}

h2 {
  color: rgb(247, 247, 247);
  color: var(--text-color);
  text-shadow: 0 0 3px #e5398c;
  text-shadow: 0 0 3px var(--main-color);
  font-family:"Teko", sans-serif;
}


/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #57b1b6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#a72463; 

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#e5398c;
  background:var(--main-color);
}


@media only screen and (max-width: 600px) {
  h1 {
    font-size:20px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}

.App {
  width:100%;
  height:100%;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-content: center;
          align-content: center;
  text-align: center;
}
.background-static {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent
    url(/static/media/noise.1690cd3d.png) repeat 0 0;
  background-repeat: repeat;
  -webkit-animation: bg-animation 1s infinite;
          animation: bg-animation 1s infinite;
  opacity: 1;
  visibility: visible;
  background-color: rgb(29, 33, 36);
  z-index:-100
}
.main-wrapper {
  width:75%;
  overflow: hidden;
}
#footer {
  position: absolute;
  font-family: var(--font-main);
  color:var(--text-color);
  bottom:0;
  opacity: 0.6;
  width:100%;
  text-align: center;
  margin-bottom: 25px;
} #footer p {
  margin: 2px;
}
@-webkit-keyframes bg-animation {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-5%, -5%);
            transform: translate(-5%, -5%);
  }
  20% {
    -webkit-transform: translate(-10%, 5%);
            transform: translate(-10%, 5%);
  }
  30% {
    -webkit-transform: translate(5%, -10%);
            transform: translate(5%, -10%);
  }
  40% {
    -webkit-transform: translate(-5%, 15%);
            transform: translate(-5%, 15%);
  }
  50% {
    -webkit-transform: translate(-10%, 5%);
            transform: translate(-10%, 5%);
  }
  60% {
    -webkit-transform: translate(15%, 0);
            transform: translate(15%, 0);
  }
  70% {
    -webkit-transform: translate(0, 10%);
            transform: translate(0, 10%);
  }
  80% {
    -webkit-transform: translate(-15%, 0);
            transform: translate(-15%, 0);
  }
  90% {
    -webkit-transform: translate(10%, 5%);
            transform: translate(10%, 5%);
  }
  100% {
    -webkit-transform: translate(5%, 0);
            transform: translate(5%, 0);
  }
}
@keyframes bg-animation {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-5%, -5%);
            transform: translate(-5%, -5%);
  }
  20% {
    -webkit-transform: translate(-10%, 5%);
            transform: translate(-10%, 5%);
  }
  30% {
    -webkit-transform: translate(5%, -10%);
            transform: translate(5%, -10%);
  }
  40% {
    -webkit-transform: translate(-5%, 15%);
            transform: translate(-5%, 15%);
  }
  50% {
    -webkit-transform: translate(-10%, 5%);
            transform: translate(-10%, 5%);
  }
  60% {
    -webkit-transform: translate(15%, 0);
            transform: translate(15%, 0);
  }
  70% {
    -webkit-transform: translate(0, 10%);
            transform: translate(0, 10%);
  }
  80% {
    -webkit-transform: translate(-15%, 0);
            transform: translate(-15%, 0);
  }
  90% {
    -webkit-transform: translate(10%, 5%);
            transform: translate(10%, 5%);
  }
  100% {
    -webkit-transform: translate(5%, 0);
            transform: translate(5%, 0);
  }
}

@media only screen and (max-width: 600px) {
  .main-wrapper {
      width:100%;
  }
  #footer p{
    /* position: absolute; */
    font-size:9px;
  }
}
.content{
 margin:1vh auto;
 width:100%;
 display:-webkit-flex;
 display:flex;
 -webkit-flex-flow:row wrap;
         flex-flow:row wrap;
 min-height: 550px;
 text-align: center;
 border: 3px solid var(--text-color);
 box-shadow: 0px 0px 7px var(--off-color);


}
.nes-container.is-dark::after {
  background-color:transparent !important;
}
.left {
  width:39%;
  display: -webkit-flex;
  display: flex;
}

.right {
  width:59%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  height:100%;
}
.social img {
  margin:auto;
  width:25px
}
.social a {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 10px;
  color:var(--text-color)
}
.social a:hover {
  color:var(--main-color)
}
.overflow {
  overflow:auto;
}
.item {
  position: relative;
}

#pixel-gif {
    width:75%;
    margin: auto;
    
}

.btn {
  -webkit-animation: beat 1.25s linear infinite alternate;
          animation: beat 1.25s linear infinite alternate;
  width:50%;

}


ul {
    list-style: none;
    margin:auto;
    /* overflow: hidden; */
    max-width: -webkit-max-content;
    max-width: max-content;
    box-shadow: 0px 0px 10px var(--main-color);
    z-index:9 !important;
    
}



li {
    margin: 13px auto;
    font-family: var(--font-main);
    color: white;
    opacity: 0.7;
    text-shadow: 2px 0px 2px rgb(255, 0, 21, 0.6), -2px 0px 2px var(--off-color);
    text-align: left;
    transition: border-bottom 0.7s ease;
}
li::before {
  visibility: hidden;
  content: "▶ "
}
li:hover::before {
  visibility: visible;
  content: "▶ ";
  color: white;
  opacity: 0.7;
  text-shadow: 2px 0px 2px rgb(255, 0, 21, 0.6), -2px 0px 2px var(--off-color);
  
}

li:hover, .btn:hover {
    opacity: 0.9;
    -webkit-animation: beat 1.15s linear infinite alternate;
            animation: beat 1.15s linear infinite alternate;
  }
  @-webkit-keyframes beat {
    0% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    25% {
      -webkit-transform: scale(1.10);
              transform: scale(1.10);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    75% {
      -webkit-transform: scale(1.10);
              transform: scale(1.10);
    }
    100% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
  }
  @keyframes beat {
    0% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
    25% {
      -webkit-transform: scale(1.10);
              transform: scale(1.10);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    75% {
      -webkit-transform: scale(1.10);
              transform: scale(1.10);
    }
    100% {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }
  }
@media only screen and (max-width: 600px) {
    .content {
     min-height: 450px;
     border: 0px solid var(--off-color);
     box-shadow: 0px 0px 0px var(--off-color);
    }
    .left {
      height:30%
    }
    .right {
      height:70%;
    }
    #pixel-gif {
      width:30%;
    }
    .content > div {
      width:100%;
      
    }
    .social a span {
      display:none;
    }
    ul {
      width:90%;
      margin:30% auto;
    }

    li {
      text-align: center;
      font-size: 12px;
    }
    li::before {
      content: "‎"
    }
}
.le-modal {
    width: 90%;
    height:86%;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background:#212529;
    border: 3px white solid;
    z-index:10;
    padding:1rem;
    background:black;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: auto;
    color:var(--text-color);
 };

/* .modal-button {
    background:white !important;
    width:50%  !important;
    margin:1vh auto  !important;
    height:min-content  !important;
    font-size:15px  !important;
} */


.about-me {
    padding:1vh
}
@media only screen and (max-width: 600px) {
    p {
        font-size:10px
    }
    .about-me button {
        color:var(--main-color);
    }
}
.technologies {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content:center;
            justify-content:center;
}
.tech img {
    width:200px;
    margin:15px;

}
@media only screen and (max-width: 900px) {
    .tech img {
        width:100px;
    }
}
.project {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-flow: wrap column;
            flex-flow: wrap column;
    border: 1px solid var(--off-color);
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0px 0px 5px 1px var(--off-color);
    margin:1vh auto;
    padding:1vh
}
.project  img{
    width:50%;
}
.project a {
     margin:5px; 
     padding:3px;
     border:1px solid var(--off-color);
     width:40%;
}
.project a:hover {
    color:var(--off-color)
}
@media only screen and (max-width: 900px) {
    .project  img{
        width:90%;
    }
    .project p {
        font-size: 10px;
    }
    .project a {
        width:100%;
    }
}
/* #main-banner {
    display:flex;
    background-image:url("../assets/buidings.gif");
    background-position: center;
    background-repeat: repeat-x;
    background-size: cover;
    image-rendering: pixelated;
    animation: 120s panorama infinite linear;
    justify-content:space-around;
    align-items:center;
    width:100%;
    flex-flow:row wrap;

    height:25vh;

    Code in case this panoram looks badd :<
} */

#main-banner {
    display:-webkit-flex;
    display:flex;
    background-image:
        url(/static/media/fence.d826b25e.png),
        url(/static/media/train.8172ca89.gif),
        url(/static/media/buidings.8eeec1b4.gif);

    background-position: 
        0 0,
        -10000px 50%,
        0 0
    ;
    background-repeat: repeat-x;
    background-size: 
       cover;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: pixelated;
    -webkit-animation: 120s panorama infinite linear;
            animation: 120s panorama infinite linear;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    -webkit-align-items:center;
            align-items:center;
    width:100%;
    -webkit-flex-flow:row wrap;
            flex-flow:row wrap;

    height:25vh;
}


#name-container > h1 {
    color: red;
    margin: 0 auto;
  }

#me::before {
    content: "JA";
    font-family: var(--font-main);
    font-size: 30px;
    color: var(--text-color);
    text-shadow: 0px 0px 10px var(--main-color);
    opacity: 1;
    text-align: center;
}
#me {
    background-image: url(/static/media/pixel_me.06fe76f0.png);
    width: 10vw;
    min-height: 10vw;
    border-radius: 50%;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: pixelated;
    opacity: 0.85;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 2px -2px 1px 1px rgb(255, 0, 21, 0.6),
        -2px 2px 1px 1px rgb(109, 249, 247, 0.6);

    transition: background-image 0.5s ease-in-out;
}
#me:hover {
    background-image: url(/static/media/me.ee9b90c5.png)
}

#menu {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    font-family: var(--main-font);
}

@-webkit-keyframes panorama {
    100% {
        background-position: 
        -5000px 0%,
        0px 0,
        -1300px 0%
         
    }
}

@keyframes panorama {
    100% {
        background-position: 
        -5000px 0%,
        0px 0,
        -1300px 0%
         
    }
}

@media only screen and (max-width: 600px) {
    #me {
        width:25vw;
        height:25vw;
    }
    #me::before {
        font-size:15px;
        
    }
}

.s-div {
    display:-webkit-flex;
    display:flex;
    background:rgb(88,105,157);
    background: linear-gradient(180deg, 
     rgba(13,48,112,1) 3%,
     rgba(88,105,159,1) 37%, 
     rgba(184,105,172,1) 65%, 
     rgba(253,236,219,1) 90%);
    width:100%;
    height:100vh;
    overflow: scroll;
}
.s-text {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
    max-height: 100vh;
    width:80%;

}
.s-text p {
    font-family: 'Quicksand', sans-serif;
    font-size:2rem;
    font-weight: bold;
    transition: all 0.25s

}
.s-title {
    font-family: 'Seaweed Script', cursive;
    color:white;
    font-size: 4rem;
}
.s-div img {
    height:-webkit-min-content;
    height:min-content;
    bottom:0;
    -webkit-align-self: flex-end;
            align-self: flex-end;

    transition: all 0.25s
}

@media only screen and (max-width: 600px) {
    .s-div {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      
    }
    .s-text p {
        font-size: 1.1rem;
    }
    .s-div img {
     -webkit-order:5;
             order:5;
     margin:auto;
     -webkit-transform:scale(0.5);
             transform:scale(0.5)
    }

  }



