@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko&display=swap");

:root {
  --text-color: rgb(247, 247, 247);
  --main-color: #e5398c;
  --off-color: rgb(109, 249, 247, 0.6);
  --font-main: "Press Start 2P", cursive;
  --font-text: "Teko", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    outline:none;
}
body {
  background: #242025;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

h1 {
  max-width: max-content;
  font-size: 40px;
  font-family: var(--font-main);
  border-bottom: 3px solid var(--main-color);
  opacity: 0.6;
  margin:auto 10px;
}
.retro {
  color: var(--text-color);
  opacity: 0.9;
  text-shadow: 5px 0px 2px rgb(255, 0, 21, 0.6), -5px 0px 2px var(--off-color);
}

h2 {
  color: var(--text-color);
  text-shadow: 0 0 3px var(--main-color);
  font-family:"Teko", sans-serif;
}


/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #57b1b6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#a72463; 

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:var(--main-color);
}


@media only screen and (max-width: 600px) {
  h1 {
    font-size:20px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}