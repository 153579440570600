.project {
    display:flex;
    flex-flow: wrap column;
    border: 1px solid var(--off-color);
    align-items: center;
    box-shadow: 0px 0px 5px 1px var(--off-color);
    margin:1vh auto;
    padding:1vh
}
.project  img{
    width:50%;
}
.project a {
     margin:5px; 
     padding:3px;
     border:1px solid var(--off-color);
     width:40%;
}
.project a:hover {
    color:var(--off-color)
}
@media only screen and (max-width: 900px) {
    .project  img{
        width:90%;
    }
    .project p {
        font-size: 10px;
    }
    .project a {
        width:100%;
    }
}